import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Linkify from 'react-linkify'

import { regexEscape } from 'lib/utils/regexManager'
import i18n from 'lib/i18n'

import styles from './Text.module.scss'
import ErrorBoundary from '../ErrorBoundary'

export const Text = (props) => {
  const {
    as: Component = 'span',
    size = 512,
    linkify,
    children,
    truncate,
    fontSize = 'base',
    ellipsis,
    className,
    translate,
    fontWeight = 400,
    translateProps,
    highlightText,
    ...rest
  } = props

  const [isReadMore, setReadMore] = useState(truncate)

  if (!props.children) {
    return null
  }

  const toggleReadMore = () => setReadMore((prev) => !prev)

  const textClass = classNames(styles.textBase, {
    [styles[fontSize]]: fontSize,
    [styles[`weight-${fontWeight}`]]: fontWeight,
    [className || '']: className,
    [styles.ellipsis]: ellipsis,
  })

  const buttonClass = classNames(styles.readMoreButton, {
    [styles[fontSize]]: fontSize,
  })

  const linkifyComponentDecorator = (href, text, key) => (
    <a
      href={href}
      key={key}
      target="_blank"
      rel="noreferrer"
      className={styles.link}
    >
      {text}
    </a>
  )

  const highlightTextFn = (text, highlightText) => {
    if (!highlightText || typeof text !== 'string') return text

    const regex = new RegExp(`(${regexEscape(highlightText)})`, 'gi')
    const normalizedHighlight = highlightText.toLowerCase()

    const parts = text
      .split(regex)
      .map((part, index) => (
        <React.Fragment key={index}>
          {part.toLowerCase() === normalizedHighlight ? (
            <span className={styles.highlight}>{part}</span>
          ) : (
            part
          )}
        </React.Fragment>
      ))

    return <>{parts}</>
  }

  const readMoreText = (text, size) => {
    return `${text.slice(0, size)}...`
  }

  const RenderChild = () => {
    let finalText = children

    if (translate) {
      try {
        finalText = i18n?.t?.(finalText, translateProps)
      } catch (error) {
        return finalText
      }
    }

    if (truncate && finalText.length > size) {
      if (isReadMore) {
        finalText = (
          <>
            {readMoreText(finalText, size)}
            {
              <button className={buttonClass} onClick={toggleReadMore}>
                Read More
              </button>
            }
          </>
        )
      } else {
        finalText = (
          <>
            {finalText}
            <button className={buttonClass} onClick={toggleReadMore}>
              Read Less
            </button>
          </>
        )
      }
    }

    if (linkify) {
      finalText = (
        <Linkify componentDecorator={linkifyComponentDecorator}>
          {finalText}
        </Linkify>
      )
    }

    if (highlightText) {
      finalText = highlightTextFn(finalText, highlightText)
    }

    return finalText
  }

  return (
    <ErrorBoundary componentName="Text" errorUI={<span>!</span>}>
      <Component className={textClass} {...rest}>
        <RenderChild />
      </Component>
    </ErrorBoundary>
  )
}

Text.fontSize = {
  p8: 'xxs',
  p11: 'xss',
  p12: 'xs',
  p14: 'base',
  p16: 'sm',
  p18: 'lg',
  p20: 'xl',
  p24: 'xl2',
  p30: 'xl3',
  p36: 'xl4',
  p40: 'xl5',
  p48: 'xl6',
}

Text.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  linkify: PropTypes.bool,
  children: PropTypes.node,
  truncate: PropTypes.bool,
  fontSize: PropTypes.string,
  ellipsis: PropTypes.bool,
  className: PropTypes.string,
  translate: PropTypes.bool,
  fontWeight: PropTypes.oneOf([300, 400, 500, 600, 700]),
  highlightText: PropTypes.string,
  translateProps: PropTypes.any,
  title: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
}
