let debugMode = false
try {
  debugMode = localStorage?.getItem?.('debugMode') === '1' || false
} catch (error) {
  window.console.log('Error getting debugMode', error)
}
export const logger = (...args: unknown[]) => {
  const timestamp = (Date.now() - (window?.globals?.timestamp || 0)) / 1000
  if (debugMode) {
    window.console.log(...args, timestamp + 's')
    // window.console.trace(...args)
  }
}
